<template>
    <BoxLayout>
        <div class="alert alert-warning" role="alert" v-if="alertMessage">{{alertMessage}}</div>
        <form v-if="!isBlocked" v-on:submit.prevent="signIn">
            <div class="mb-2">
                <TextInput
                    label="Email address"
                    v-model="v$.email.$model"
                    :v="v$.email"
                />
            </div>
            <div class="mb-2">
                <TextInput
                    label="Password"
                    v-model="v$.password.$model"
                    :v="v$.password"
                    type="password"
                />
            </div>
            <div class="mb-3 d-flex justify-content-end"><router-link to="/reset-password">Forgotten your password?</router-link></div>
            <button type="submit" class="btn w-100 btn-primary">Sign in</button>
            <hr class="mt-4 mb-4">
            <h2 class="h6">Need to register?</h2>
            <p>To register for Swift Connect, contact our customer support team on 01204 323 323 or <a href="mailto:info@swiftdental.co.uk">info@swiftdental.co.uk</a> and they will be happy to help you.</p>
        </form>
        <div v-if="isBlocked" class="text-center">
            <p>You have been blocked from signing in as you have made too many failed attempts.</p>
            <p>Please wait {{delayMinutes}} minutes before trying to <router-link to="/login" @click="resetBlock">sign in</router-link> again.</p>
        </div>
    </BoxLayout>
</template>

<script>
    import { ref } from 'vue';
    import api from "@/services/api";
    import BoxLayout from '@/components/BoxLayout.vue';
    import TextInput from '@/components/TextInput.vue';
    import useVuelidate from '@vuelidate/core'
    import { required } from '@vuelidate/validators'

    export default {
        data() {
            return {
                email: '',
                password: '',
                alertMessage: null,
                isBlocked: false,
                isProcessing: false,
                delayMinutes: 2,
            }
        },
        components: {            
            BoxLayout,
            TextInput,
        },
        validations () {
            return {
                email: { required, $autoDirty: true },
                password: { required },
            }
        },
        setup () {
            const externalResults = ref({});
            return {
                externalResults,
                v$: useVuelidate({ $externalResults: externalResults }),
            }
        },
        methods: {
            signIn: async function () {
                if(this.isProcessing)
                {
                    return false;
                }

                this.isProcessing = true;
                
                // Vuelidate's $clearExternalResults isn't working as per https://vuelidate-next.netlify.app/advanced_usage.html#clearing-externalresults
                // Instead we set autoDirty on the validators above and change the validator to make the attribute not dirty. 
                this.email = this.email + ' ';
                this.email = this.email.trim();

                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    this.requestCookie();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                    this.isProcessing = false;
                }
            },
            requestCookie: function()
            {
                api
                .get('/sanctum/csrf-cookie')
                .then(() => {
                    this.requestSignIn();
                })
                .catch(() => {
                    this.alertMessage = 'There was an problem signing in. Please try again later.';
                    this.isProcessing = false;
                });
            },
            requestSignIn: function()
            {
                api
                .post('/api/v1/login', {
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    this.storeSignIn();
                })
                .catch((error) => {
                    if (error.response)
                    {
                        if(error.response.status === 429)
                        {
                            this.isBlocked = true;
                            this.delayMinutes = error.response.data.errors.delay;
                        }
                        else
                        {
                            Object.assign(this.externalResults, error.response.data.errors);
                        }
                    } 
                    else
                    {
                        this.alertMessage = 'There was an problem signing in. Please try again later.';
                    }

                    this.isProcessing = false;
                });
            },
            storeSignIn: function()
            {
                this.$store.dispatch('signIn')
                .then(() => {
                    this.checkPractices();                   
                })
                .catch(() => {
                    this.isProcessing = false;
                });
            },
            checkPractices: function()
            {
                this.$store.dispatch('fetchPractices')
                .then((practices) => {
                    if(practices.length === 1)
                    {
                        this.$store.dispatch('setCurrentPracticeId', practices[0].id);
                        this.$store.dispatch('setCurrentPractice');
                    }
                })
                .finally(() => {
                    this.$router.replace('/');
                })
            },
            resetBlock: function()
            {
                this.isBlocked = false;
            }
        }
    }
</script>